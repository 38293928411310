import React from "react"

import PageTitle from "../components/page-title"
import Seo from "../components/seo"

const AboutIndex = () => (
    <>
        <Seo
            title="About"
            description="The Opworks story, where our passion for the AWS cloud started, and how we came into being as AWS cloud practitioners and solutions architects."
        />

        <PageTitle summary="Opworks has over 10 years of AWS cloud experience, covering everything from multi-environment setup, onboarding and migrations, management and cost-optimisation along with deployment and CI for a variety of online platforms and projects spanning both the public and private sectors."
                   title="About"/>

        <div className={"mb-10 lg:mb-32 px-6 md:px-12 mx-auto prose prose-lg"}>
            <p>Opworks grew out of our parent company Enovate, where our team has gained invaluable experience migrating, running, scaling and maintaining a variety of applications and workloads on the AWS cloud for a diverse selection of clients.</p>
            <p>Our journey with AWS began in 2008, when in search of a suitable off-site backup solution we discovered Amazon S3. Since then we've never looked back and we now utilise the AWS platform for a multitude of purposes and workloads.</p>
            <p>We are passionate about the potential the AWS cloud provides to organisations of all shapes and sizes.</p>
        </div>
    </>
)

export default AboutIndex
